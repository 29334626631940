<template>
  <div class="_success">
    <div class="left">
        <img src="../../assets/img/icon/10.png" alt="">
    </div>
    <div class="right">
      <p class="_title">注册成功</p>
      <p class="desc">请前往您的邮箱,激活账号吧！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {};
  },
  watch: {},

  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
._success {
  padding: 0 10px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  .left{
      margin-left: -20px;
      img{
          width: 100px;
          height: 100px;
      }
  }
 .right{
      ._title {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;

    text-align: center;
  }
  .desc {
      color: orange;
      font-size: 20px;
    text-align: center;
  }
 }
}
</style>
